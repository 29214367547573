import { gql, isApolloError } from '@apollo/client'
import { Maybe } from 'graphql/jsutils/Maybe'
import { NextSeoPropsWithRequiredFields } from '@/layout/Seo'
import { getMediaMetaData } from '@/layout/Seo/SeoHelper'
import { getWebClient } from '@/services/ApolloClient'
import { projectMetaFragment } from '@/services/ProjectsService'
import { Story, StoryAuthor } from '@/services/StoriesService'
import { GetProjectStoryQuery, UserStoryQuery } from '@/types/codegen-federation'
import { getDisplayCountry } from '@/utils/DisplayCountryUtil'
import { logger } from '@/utils/logging'
import { getBasePath } from '@/utils/sitemap/base'
import { DeepNonNullable } from '@/utils/types'
import { storyFragment } from '@/views/WatchProjectView/CommunityView/StoriesList'
import { shareTitle } from '@/views/WatchProjectView/CommunityView/StoryCard'

const getProjectStoryQuery = gql`
  ${projectMetaFragment}
  ${storyFragment}
  query getProjectStory($slug: String!, $storyId: ID!, $did: String) {
    project(slug: $slug) {
      ...ProjectMeta
    }
    story(id: $storyId) {
      ...StoryFragment
    }
  }
`

const getUserStory = gql`
  ${projectMetaFragment}
  query UserStory($slug: String!, $userStoryId: ID!) {
    project(slug: $slug) {
      ...ProjectMeta
    }
    userStory(id: $userStoryId) {
      user {
        firstName
        uuid
        country
        image
      }
      id
      authorLocation
      totalLikes
      text
      duration
      videoUri
      posterCloudinaryPath
      title
      momentId
      momentTimeCode
      subtitle
      isVideoStory
      videoGuid
      isLikedByUser
      isMomentStory
      isVip
      publishDate
    }
  }
`

type GetStoryParams = { slug: string; storyId: string; locale?: string }

export type ProjectStory = NonNullable<GetProjectStoryQuery>
export type ValidatedStory = DeepNonNullable<
  Pick<NonNullable<ProjectStory['story']>, 'id' | 'totalLikes' | 'authorLocation'>,
  2
> &
  Pick<NonNullable<ProjectStory['story']>, 'thankYouNote' | 'userProfile' | 'video'> & {
    publishDate: string
    likedByMe?: boolean | null
  }

export async function getStory({
  slug,
  storyId,
  locale,
}: GetStoryParams): Promise<
  { story: ValidatedStory | undefined; project: ProjectStory['project'] | undefined } | undefined
> {
  try {
    const client = getWebClient({ locale })
    const projectStoryResult = await client.query<GetProjectStoryQuery>({
      query: getProjectStoryQuery,
      variables: {
        slug,
        storyId,
        did: '',
      },
      errorPolicy: 'all',
    })

    return { story: validateStory(projectStoryResult.data?.story), project: projectStoryResult.data?.project }
  } catch (err) {
    if (err instanceof Error && isApolloError(err)) {
      if (err.graphQLErrors.some((e) => e.message === 'bad_arg')) {
        // Sometimes we provide invalid storyId inputs because we don't know what kind of story we're fetching.
        // If we get a bad_arg error, we'll try to fetch a collectible story instead.
        return
      }
    }
    logger().error(`Failed to fetch a project story`, { slug, storyId, locale, err })
  }
}

function validateStory(maybeStory: Maybe<ProjectStory['story']>): ValidatedStory | undefined {
  if (!maybeStory) {
    return
  }
  if (
    !maybeStory.id ||
    !maybeStory.publishDate ||
    typeof maybeStory.publishDate !== 'string' ||
    maybeStory.totalLikes === undefined ||
    maybeStory.totalLikes === null ||
    typeof maybeStory.totalLikes !== 'number' ||
    !maybeStory.authorLocation
  ) {
    logger().error(`Missing or invalid required fields in story`, { maybeStory })
    return
  }

  const validatedStory: ValidatedStory = {
    id: maybeStory.id,
    publishDate: maybeStory.publishDate,
    totalLikes: maybeStory.totalLikes,
    authorLocation: maybeStory.authorLocation,
  }

  if (typeof maybeStory.likedByMe === 'boolean') {
    validatedStory.likedByMe = maybeStory.likedByMe
  } else if (typeof maybeStory.likedByMe === 'object' && maybeStory.likedByMe !== null) {
    if (!!maybeStory.likedByMe.id && typeof maybeStory.likedByMe.id === 'string') {
      validatedStory.likedByMe = true
    } else {
      logger().warn(`Invalid likedByMe field in story. This optional field will be omitted.`, { maybeStory })
    }
  }

  validatedStory.thankYouNote = maybeStory.thankYouNote
  validatedStory.userProfile = maybeStory.userProfile
  validatedStory.video = maybeStory.video

  return maybeStory as ValidatedStory
}

type GetCollectibleStoryParams = { userStoryId: string; slug: string; locale?: string }

export type CollectibleStory = NonNullable<UserStoryQuery>

export async function getCollectibleStory({
  locale,
  userStoryId,
  slug,
}: GetCollectibleStoryParams): Promise<CollectibleStory | undefined> {
  try {
    const client = getWebClient({ locale })
    const collectibleStoryResult = await client.query<UserStoryQuery>({
      query: getUserStory,
      variables: {
        userStoryId,
        slug,
      },
      errorPolicy: 'all',
    })

    return collectibleStoryResult.data
  } catch (err) {
    if (err instanceof Error && isApolloError(err)) {
      if (err.graphQLErrors.some((e) => e.message === 'bad_arg')) {
        // Sometimes we provide invalid userStoryId inputs because we don't know what kind of story we're fetching.
        // If we get a bad_arg error, we'll try to fetch a project story instead.
        return
      }
    }
    logger().error(`Failed to fetch a collectible story`, { slug, userStoryId, locale, err })
  }
}

export function getAuthorDisplay({
  defaultViewerName,
  firstName,
  country,
  authorLocation,
  locale = 'en',
}: StoryAuthor & { locale?: string }): {
  viewerName: string
  fullDisplay: string
} {
  const viewerName = firstName || defaultViewerName
  const authorCountry = country || authorLocation
  let locationDisplay = ''

  if (authorCountry) {
    locationDisplay = ` from ${getDisplayCountry(authorCountry, locale)}`
  }

  return {
    viewerName,
    fullDisplay: viewerName + locationDisplay,
  }
}

export interface StorySeqParams {
  story: Story
  projectSlug: string
  projectName: string
  posterUrl: string
  locale?: string
}

export function getStorySEO({
  locale = 'en',
  projectName,
  posterUrl,
  projectSlug,
  story,
}: StorySeqParams): NextSeoPropsWithRequiredFields {
  const url = `${getBasePath(locale)}/watch/${projectSlug}/community/stories/${story?.id}`
  const { viewerName } = getAuthorDisplay({
    authorLocation: story?.authorLocation,
    country: story?.userProfile?.country,
    defaultViewerName: 'a viewer',
    firstName: story?.userProfile?.firstName,
    locale,
  })

  let title = shareTitle({ project: projectName, viewerName })
  let description = ''

  if (story?.video?.title) {
    title = story?.video?.title
  }

  if (story?.video) {
    description =
      story?.video?.subtitle || story?.video?.title || story?.video?.name || `Come and see the impact of ${name}`
  } else if (story?.thankYouNote) {
    description = story?.thankYouNote

    if (description.length > 160) {
      description = description.substring(0, 150) + '...'
    }
  }

  return getMediaMetaData({
    url,
    cloudinaryImagePath: story?.video?.posterCloudinaryPath || posterUrl,
    cloudinaryTransformation: 'c_pad,q_auto,b_auto',
    description,
    title: `${projectName} Story: ${story?.video?.title || viewerName} | Angel Studios`,
    openGraph: {
      title,
      url,
    },
    type: 'tv_show',
  })
}
