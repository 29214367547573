import React from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { LinkButton } from '@/atoms/Button'
import { Image } from '@/atoms/Image'
import { AsFigCaption, AsH2, HeadingXS, LabelMD, ParagraphMD } from '@/atoms/Text'
import { paths } from '@/constants'
import { LightFranchise } from '@/services/FranchiseService'
import { useTranslate } from '@/utils/translate/translate-client'

export interface FranchiseLinkProps {
  projectName: string
  className?: string
  franchise?: LightFranchise
  projectType?: string
}

export const FranchiseLink: React.FC<FranchiseLinkProps> = ({ projectName, className, franchise, projectType }) => {
  const { t } = useTranslate('theatrical-presales')
  const { push } = useRouter()
  const defaultProject =
    franchise?.projects?.filter((project) => project?.projectType === 'movie')[0] ?? franchise?.projects?.[0]

  const franchiseHref = franchise?.slug ? `${paths.franchise.index}/${franchise.slug}` : null
  const franchiseName = franchise?.name

  const franchiseImgUrl =
    franchise?.images?.[0]?.cloudinaryPath ??
    defaultProject?.title?.landscapeAngelImage3?.cloudinaryPath ??
    defaultProject?.title?.landscapeAngelImage?.cloudinaryPath ??
    null
  const description =
    franchise?.description && franchise.description.length > 0
      ? franchise.description
      : defaultProject?.theaterDescription

  if (
    !franchise ||
    !franchiseHref ||
    (franchise?.projects?.length && franchise.projects.length <= 1) ||
    franchise?.slug === 'drybar-comedy' ||
    franchise?.slug === 'dry-bar-comedy'
  )
    return null

  return (
    <div className="pt-8 lg:pt-16">
      <figure
        className={classNames(
          'flex flex-col gap-6 p-8 pt-16 text-center text-white sm:px-8 sm:py-16 md:flex-row md:justify-center md:gap-12 md:px-16 lg:gap-20 2xl:gap-28',
          className,
        )}
      >
        {franchiseImgUrl && (
          <div className="relative">
            <Image
              className="max-w-full cursor-pointer rounded-2xl shadow-dark-5 duration-[400ms] ease-in-out hover:opacity-85 md:mx-0 md:max-h-[210px] md:max-w-fit lg:max-h-[260px] xl:max-h-[360px] "
              alt={t('altTextForCabriniLivestream', '{{ projectName }} Livestream', { projectName })}
              src={franchiseImgUrl}
              width={639}
              height={360}
              onClick={() => push(franchiseHref)}
            />
          </div>
        )}
        <div className="flex flex-col items-start justify-center gap-2 text-left">
          <HeadingXS
            as={AsH2}
            id="franchise-section-title"
            className="photon-title-md md:photon-title-lg lg:photon-heading-xs max-w-[288px]"
            weight="bold"
          >
            {t('watchAllThingsFranchise', 'Watch All Things {{ franchiseName }}', { franchiseName })}
          </HeadingXS>
          <ParagraphMD
            as={AsFigCaption}
            className="photon-caption-sm lg:photon-paragraph-md weight-[350] max-w-[341px] text-core-gray-300"
          >
            {description}
          </ParagraphMD>
          <LinkButton
            className="mt-2 w-fit px-8 py-4 text-core-gray-950 md:px-8 lg:px-16"
            href={franchiseHref}
            variant="white"
          >
            <LabelMD weight="bold">
              {projectType?.toLowerCase() === 'series'
                ? t('learnMore', 'Learn More')
                : t('continueTheStory', 'Continue the Story')}
            </LabelMD>
          </LinkButton>
        </div>
      </figure>
    </div>
  )
}
