import React, { useEffect, useMemo, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import classNames from 'classnames'
import { Maybe } from 'graphql/jsutils/Maybe'
import { Else, If, Then, When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { Image } from '@/atoms/Image'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { Placeholder } from '@/atoms/Placeholder'
import { AsH3, TitleLG } from '@/atoms/Text'
import { paths, slugs } from '@/constants'
import { useProjectMeta } from '@/contexts/ProjectMetaContext'
import { HorizontalCollection } from '@/molecules/HorizontalCollection'
import { Menu } from '@/molecules/Menu'
import { PortraitRowCollection } from '@/molecules/PortraitRowCollection'
import { EpisodesHorizontalScroller } from '@/organisms/EpisodesHorizontalScroller'
import { HorizontalLivestreamScroller } from '@/organisms/HorizontalLivestreamScroller'
import { useShopifyConfig } from '@/organisms/HorizontalShopifyMerchScroller/ShopifyConfigContext'
import { ProjectJourney } from '@/organisms/ProjectJourney'
import { useProjectVideos, VideoHorizontalScroller } from '@/organisms/VideoHorizontalScroller'
import { Page } from '@/page'
import { Downloadable } from '@/services/DownloadablesService'
import { LightFranchise } from '@/services/FranchiseService'
import { Livestream } from '@/services/LivestreamService'
import { sortLivestreams } from '@/services/LivestreamService/LivestreamService'
import { isSellingTickets } from '@/services/PhaseManagerService'
import { Project, Season, VideoMetaData } from '@/services/ProjectsService'
import { PageDataContext, PageModel } from '@/services/RenderService'
import { useHydraUser } from '@/services/UserService'
import { episodesToMedias, isASneakPeek } from '@/utils/EpisodeUtil'
import { useLocale } from '@/utils/LocaleUtil'
import { useSafeTrack } from '@/utils/analytics'
import { getProjectTheme } from '@/utils/project-themes'
import { useTranslate } from '@/utils/translate/translate-client'
import { isDefined } from '@/utils/types'
import { hasSeasonEarlyAccess } from '@/utils/users/users'
import { useWatchProject } from '@/views/WatchProjectView/useWatchProject'
import { DownloadSection } from '@/views/WatchTabView/DownloadSection'
import { FaqSection } from './FaqSection'
import { MerchPifSection } from './MerchPifSection'

const getLivestreamsByProject = gql`
  query getLivestreamsByProject($projectSlug: String!) {
    project(slug: $projectSlug) {
      id
      livestreams(includeGuildOnly: true) {
        projectSlug
        name
        bannerCloudinaryPath
        id
        guid
        live
        isEnded
        startAt
        unavailableReason
      }
    }
  }
`

interface LivestreamDataStructure {
  project: {
    livestreams: Livestream[]
  }
}

interface WatchTabViewProps {
  downloadables: Downloadable[]
  slug: string
  projectData: Project
  loadingProjectData: boolean
  page: Maybe<PageModel>
  pageDataContext: Maybe<PageDataContext>
  isGuildMember?: boolean
  franchise?: LightFranchise
  hasWatchableReasons?: boolean
  isGeoblockedProject?: boolean
}

interface BonusData {
  id: string
  name: string
  videos: VideoMetaData[]
  loading: boolean
}

export const WatchTabView: React.FC<WatchTabViewProps> = ({
  downloadables = [],
  slug,
  projectData,
  loadingProjectData,
  page,
  pageDataContext,
  isGuildMember = false,
  franchise,
  hasWatchableReasons = false,
  isGeoblockedProject = false,
}) => {
  const { groups } = useHydraUser()
  const numSeasons = projectData?.seasons?.length
  const { data: livestreamData, loading: loadingLivestreams } = useQuery<LivestreamDataStructure>(
    getLivestreamsByProject,
    {
      variables: { projectSlug: slug },
      errorPolicy: 'all',
    },
  )
  const { pifEnabled } = useProjectMeta()
  const { videos: bonusVideos, loading: loadingBonus } = useProjectVideos(slug, 'bonus')
  const { videos: trailerVideos, loading: loadingTrailers } = useProjectVideos(slug, 'trailer')
  const track = useSafeTrack()
  const { project, preferredSeason } = useWatchProject({ projectSlug: slug, skip: numSeasons === 0, isGuildMember })
  const { hasShopifyConfig, storeUrl, accessToken, handle, storeHref } = useShopifyConfig()
  const { t } = useTranslate('watch')
  const [payItForwardCarouselVisited, setPayItForwardCarouselVisited] = useState(false)
  const [downloadablesCarouselVisited, setDownloadablesCarouselVisited] = useState(false)
  const [sortedLivestreams, setSortedLivestreams] = useState<Livestream[]>([])
  const [selectedSeason, setSelectedSeason] = useState<Season | undefined>(preferredSeason)
  const isDryBar = slug === slugs.dryBar
  const defaultProject =
    franchise?.projects?.filter((project) => project?.projectType === 'movie')[0] ?? franchise?.projects?.[0]

  const mightHaveSeasons = Boolean(selectedSeason) || loadingProjectData

  const projectTheme = getProjectTheme(slug)
  const { locale } = useLocale()

  const bonusContent: BonusData[] = useMemo(() => {
    return [
      { name: t('extras', 'Extras'), id: 'extras', videos: bonusVideos, loading: loadingBonus },
      { name: t('trailers', 'Trailers'), id: 'trailers', videos: trailerVideos, loading: loadingTrailers },
    ]
  }, [bonusVideos, loadingBonus, trailerVideos, loadingTrailers, t])

  const displayDownloadables = downloadables.length > 0
  const displayLivestreams =
    loadingLivestreams ||
    (livestreamData &&
      livestreamData?.project &&
      livestreamData?.project?.livestreams &&
      livestreamData?.project?.livestreams.length > 0)
  const displayPills =
    bonusVideos?.length > 0 || trailerVideos?.length > 0 || displayLivestreams || displayDownloadables
  const hasEarlyAccess = hasSeasonEarlyAccess(projectTheme.watch?.seasonEarlyAccessGroup, groups)
  const medias = episodesToMedias(selectedSeason?.episodes, {
    projectName: project?.name,
    hasEarlyAccess,
    projectType: project?.projectType,
    trailerText: t('trailer', 'Trailer'),
    locale,
  }).filter((media) => {
    return !(isASneakPeek({ slug: media?.episodeSlug ?? '', name: media?.title ?? '' }) && isGeoblockedProject)
  })

  useEffect(() => {
    switch (window?.location?.hash?.split('?')[0]) {
      case '#pay-it-forward':
        if (payItForwardCarouselVisited) return
        if (projectData && selectedSeason && !loadingBonus && !loadingTrailers && !loadingLivestreams && project) {
          setPayItForwardCarouselVisited(true)
          document.getElementById('pay-it-forward')?.scrollIntoView()
        }
        break
      case '#downloadables':
        if (downloadablesCarouselVisited) return
        if (projectData && selectedSeason && !loadingBonus && !loadingTrailers && !loadingLivestreams && project) {
          setDownloadablesCarouselVisited(true)
          document.getElementById('downloadables')?.scrollIntoView()
        }
        break
    }
  }, [
    projectData,
    selectedSeason,
    loadingBonus,
    loadingTrailers,
    loadingLivestreams,
    project,
    payItForwardCarouselVisited,
    downloadablesCarouselVisited,
  ])

  useEffect(() => {
    if (!loadingLivestreams && livestreamData && livestreamData?.project) {
      setSortedLivestreams(sortLivestreams(livestreamData?.project?.livestreams))
    }
  }, [loadingLivestreams, livestreamData])

  useEffect(() => {
    if (preferredSeason) {
      setSelectedSeason(preferredSeason)
    }
  }, [preferredSeason])

  return (
    <div className="grid grid-cols-1 gap-14">
      <div className="px-4 sm:px-8 md:px-12 xl:px-16">
        <If condition={loadingProjectData}>
          <Then>
            <Placeholder className="h-14 w-96" />
          </Then>
          <Else>
            <div className="flex w-full flex-col items-start sm:flex-row sm:items-center">
              <When condition={project?.projectType === 'series' && !page && numSeasons === 1}>
                <h2 className="photon-title-sm mb-4 mr-6 w-full text-nowrap sm:mb-0 sm:w-fit sm:min-w-[75px]">
                  {selectedSeason?.name}
                </h2>
              </When>
              <When condition={project?.projectType === 'series' && numSeasons && numSeasons !== 1}>
                <h2 className="hidden">{t('listOfEpisodes', 'List of Episodes')}</h2>
                <Menu
                  menuClassName="w-full sm:w-fit mb-2 sm:mb-0 mr-6"
                  buttonClassName="cursor-pointer"
                  options={project?.seasons as Season[]}
                  value={selectedSeason}
                  onChange={setSelectedSeason}
                />
              </When>
              {displayPills && (
                <LazyHydrate whenVisible>
                  <HorizontalCollection arrowClassName="hidden" sectionClassName="w-auto">
                    {displayLivestreams && (
                      <a
                        className="mb-2 mr-4 flex items-center whitespace-nowrap rounded-3xl bg-gray-800 px-6 py-2 sm:mb-0"
                        href="#livestreams"
                      >
                        {t('livestreams', 'Livestreams')}
                      </a>
                    )}
                    {bonusContent.map((bonus) => {
                      if (bonus.videos.length > 0) {
                        return (
                          bonus && (
                            <a
                              className="mb-2 mr-4 flex items-center whitespace-nowrap rounded-3xl bg-gray-800 px-6 py-2 sm:mb-0"
                              key={bonus.name}
                              href={`#${bonus.id}` || ''}
                            >
                              {bonus.name}
                            </a>
                          )
                        )
                      }
                    })}
                    {displayDownloadables && (
                      <a
                        className="mb-2 mr-4 flex items-center whitespace-nowrap rounded-3xl bg-gray-800 px-6 py-2 sm:mb-0"
                        href="#downloadables"
                      >
                        {t('downloads', 'Downloads')}
                      </a>
                    )}
                  </HorizontalCollection>
                </LazyHydrate>
              )}
            </div>
          </Else>
        </If>
      </div>

      {displayLivestreams && isDryBar && (
        <section id="livestreams" className="scroll-mt-24">
          <PaddedContainer fluid>
            <TitleLG
              weight="bold"
              as={AsH3}
              className={classNames(
                'transition-opacity duration-500 p-0 mb-3 md:mb-14',
                sortedLivestreams ? 'opacity-100' : 'opacity-0',
              )}
            >
              {t('liveSpecials', 'Live Specials')}
            </TitleLG>
          </PaddedContainer>
          <LazyHydrate whenVisible>
            <HorizontalLivestreamScroller
              onThumbnailClick={(livestream) => {
                track('Joined Livestream from Watch Project Carousel', {
                  program: livestream.projectSlug,
                  asset_id: livestream.guid,
                  title: livestream.name,
                })
              }}
              livestreams={sortedLivestreams}
              padding
            />
          </LazyHydrate>
        </section>
      )}

      {mightHaveSeasons && (
        <>
          <When condition={isDryBar}>
            <section id="livestreams" className="scroll-mt-24">
              <PaddedContainer fluid>
                <TitleLG
                  weight="bold"
                  as={AsH3}
                  className={classNames(
                    'transition-opacity duration-500 mb-3 md:mb-14 p-0',
                    sortedLivestreams ? 'opacity-100' : 'opacity-0',
                  )}
                >
                  {t('dryBarSpecials', 'Dry Bar Specials')}
                </TitleLG>
              </PaddedContainer>
              <EpisodesHorizontalScroller medias={medias} padding />
            </section>
          </When>
          <When condition={!isDryBar}>
            <EpisodesHorizontalScroller medias={medias} padding hasWatchableReasons={hasWatchableReasons} />
          </When>
        </>
      )}

      {!!page && !!pageDataContext && (
        <Page
          page={page}
          pageDataContext={{
            ...pageDataContext,
            contentItemConfig: { padding: true, showThumbnailTitleDescription: true },
          }}
        />
      )}

      {project &&
        franchise?.projects?.length &&
        franchise.projects.length > 1 &&
        franchise?.slug !== 'drybar-comedy' &&
        franchise?.slug !== 'dry-bar-comedy' && (
          <section aria-labelledby="franchise-section-title" className="bg-core-gray-950">
            <PaddedContainer fluid>
              <TitleLG weight="bold" as={AsH3} className={classNames('transition-opacity duration-500 mb-3 p-0')}>
                {t('moreFromFranchise', 'More From {{ franchiseName }}', { franchiseName: franchise?.name })}
              </TitleLG>
              <PortraitRowCollection haltLazyLoad>
                {franchise?.projects
                  .filter((project) => project?.slug !== projectData.slug)
                  .map((project) => {
                    if (!project) return null

                    const projectPhases = project?.primaryFlowPhases?.filter(isDefined) ?? []
                    const sellingTickets = isSellingTickets(projectPhases)

                    const href = sellingTickets
                      ? `${paths.tickets.index}/${project.slug}`
                      : `${paths.watch.index}/${project.slug}`

                    const portraitProjectPoster =
                      project?.title?.portraitAngelImage?.cloudinaryPath ??
                      project?.title?.portraitAngelImage3?.cloudinaryPath ??
                      project?.title?.portraitTitleImage?.cloudinaryPath ??
                      defaultProject?.title?.portraitAngelImage3?.cloudinaryPath

                    return (
                      <a
                        key={project?.slug}
                        className="pb-2 pr-4"
                        href={href}
                        onClick={() => track('Clicked Franchise Component Project', { project: project?.slug })}
                      >
                        <div className="inline-block h-fit duration-[400ms] ease-in-out hover:z-10 md:hover:scale-[1.05] ">
                          <Image
                            aria-label={project?.name as string}
                            alt={project?.name as string}
                            src={portraitProjectPoster as string}
                            height={490}
                            width={294}
                            className="rounded-lg bg-black"
                          />
                        </div>
                      </a>
                    )
                  })}
              </PortraitRowCollection>
            </PaddedContainer>
          </section>
        )}
      <PaddedContainer fluid>
        <ProjectJourney projectSlug={slug} className="mb-2 border-b border-white/10 pb-12" />
      </PaddedContainer>
      <MerchPifSection
        hasShopifyConfig={hasShopifyConfig}
        storeUrl={storeUrl}
        accessToken={accessToken}
        handle={handle}
        storeHref={storeHref}
        slug={slug}
        pifEnabled={pifEnabled}
        padding
        isTop
      />
      {displayLivestreams && !isDryBar && (
        <section id="livestreams" className="scroll-mt-24">
          <PaddedContainer fluid>
            <TitleLG
              weight="bold"
              as={AsH3}
              className={classNames(
                'transition-opacity duration-500 mb-3 md:mb-4 p-0',
                sortedLivestreams ? 'opacity-100' : 'opacity-0',
              )}
            >
              {t('livestreams', 'Livestreams')}
            </TitleLG>
          </PaddedContainer>
          <LazyHydrate whenVisible>
            <HorizontalLivestreamScroller
              onThumbnailClick={(livestream) => {
                track('Joined Livestream from Watch Project Carousel', {
                  program: livestream.projectSlug,
                  asset_id: livestream.guid,
                  title: livestream.name,
                })
              }}
              livestreams={sortedLivestreams}
              padding
            />
          </LazyHydrate>
        </section>
      )}
      <LazyHydrate whenVisible>
        <>
          {bonusContent.map((bonus) => {
            if (!bonus.loading && bonus.videos.length === 0)
              return <section key={bonus.id} id={bonus.id} className="hidden" />

            return (
              <section key={bonus.id} id={bonus.id} className="scroll-mt-24">
                <PaddedContainer fluid>
                  <TitleLG weight="bold" as={AsH3} className="mb-3 md:mb-4">
                    {bonus.name}
                  </TitleLG>
                </PaddedContainer>
                <VideoHorizontalScroller projectSlug={slug} videos={bonus.videos} padding />
              </section>
            )
          })}
        </>
      </LazyHydrate>
      <section id="downloadables" className="scroll-mt-[73px]">
        <LazyHydrate whenVisible>
          <DownloadSection downloadables={downloadables} projectSlug={slug} padding />
        </LazyHydrate>
      </section>
      <MerchPifSection
        hasShopifyConfig={hasShopifyConfig}
        storeUrl={storeUrl}
        accessToken={accessToken}
        handle={handle}
        storeHref={storeHref}
        slug={slug}
        pifEnabled={pifEnabled}
        padding
      />
      <section id="faqs" className="scroll-mt-[73px]">
        <LazyHydrate whenVisible>
          <PaddedContainer fluid>
            <FaqSection />
          </PaddedContainer>
        </LazyHydrate>
      </section>
    </div>
  )
}
